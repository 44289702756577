@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "HelpMe";
  src: url("./fonts/HelpMe.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes float-reverse {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
}

.animate-spin-slow {
  animation: spin 2s linear infinite;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-float-reverse {
  animation: float-reverse 3s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes crazyMove {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 50px);
  }
  100% {
    transform: translate(-100px, -50px);
  }
}

@keyframes crazyScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}

.animate-colorToggle {
  animation-name: colorToggle;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
}

@keyframes colorToggle {
  0%,
  100% {
    color: black;
  }
  50% {
    color: white;
  }
}
